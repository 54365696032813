<template>
	<div class="">
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'status'">{{scope.row.status == 0 ? '未开始' : scope.row.status == 1 ? '进行中' : '已完成'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="95" fixed="right">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-s-grid" @click="gameClick(scope.row)">玩家</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog width="80%" title="玩家详情" :visible.sync="dialogFormVisibles" :append-to-body="true">
			<gameRoom :key="timeKey" :gameRefId="gameRefId" :sort='sort'></gameRoom>
		</el-dialog>
	</div>
</template>

<script>
	import gameRoom from "../gameRoom"
	export default {
		data() {
			return {
				stageId: '',//当前阶段id
				listLoading: false,
				page: 1,
				total: 0,//分页总条数
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				checkArr: [
					{label: "阶段名称", val: "typeStageName", dis: true},
					{label: "赛制名称", val: "stageName", dis: true},
					{label: "晋级人数", val: "typeStageNum", dis: true},
					{label: "执行顺序", val: "sort", dis: true},
					{label: "进行状态", val: "status", dis: true}
				],
				dialogFormVisibles: false,//玩家详情弹窗
				gameRefId: '',
				sort: '',
				timeKey: ''
			}
		},
		props: ['gameId'],
		components: {
			gameRoom
		},
		created(){
			this.getList();
		},
		methods:{
			getList() {
				this.listLoading = true;
				
				let data = {
					entity: 'ViewFaExamGameRef',
					page: this.page,
					pageSize: 10,
					filter: '[{"col":"gameId","type":"=S","val": "' + this.gameId + '"}, {"col":"isDelete","type":"=","val": "0"}]',
					sidx: 'sort',
					sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					// res.rows.forEach(item => {
					// 	if(item.status == 1) {
					// 		this.stageId = item.id;
					// 	}
					// })
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			// 玩家
			gameClick(row) {
				this.timeKey = new Date().getTime();
				this.gameRefId = row.id;
				this.sort = row.sort;
				this.dialogFormVisibles = true;
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
</style>


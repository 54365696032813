<template>
	<div class="" v-loading.fullscreen.lock="fullscreenLoading">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
		<el-button type="primary" size="mini" icon="el-icon-s-grid" @click="abc()">表格</el-button>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="玩家名称：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入玩家名称"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-button type="primary" size="medium" icon="el-icon-s-promotion" @click="sendBtn()" style="margin-bottom: 20px;">发送消息</el-button>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%">
				<el-table-column v-for="item in checkArr" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'headImg'"><el-avatar size="medium" :src="scope.row.headImg"></el-avatar></span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="95" fixed="right">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-s-grid" @click="outClick(scope.row)">踢出</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog width="55%" title="玩家详情" :visible.sync="dialogFormVisible" :append-to-body="true">
			
		</el-dialog>
		
		<el-dialog title="发送消息" :visible.sync="dialogSendVisibles" :append-to-body="true">
			<el-form :model="sendForm" :rules="sendRules" ref="sendForm" label-width="150px">
				<el-form-item label="活动主题：" prop="title">
					<el-input v-model="sendForm.title" placeholder="请输入活动主题"></el-input>
				</el-form-item>
				<el-form-item label="赛事地点：" prop="address">
					<el-input v-model="sendForm.address" placeholder="请输入赛事地点"></el-input>
				</el-form-item>
				<el-form-item label="开始时间：" prop="startTime">
					<el-date-picker v-model="sendForm.startTime" type="datetime" placeholder="选择开始时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="温馨提醒：" prop="tips">
					<el-input v-model="sendForm.tips" placeholder="请输入温馨提醒(20个字内)" maxlength="20"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogSendVisibles = false">取 消</el-button>
				<el-button type="primary" v-loading.fullscreen.lock="fullscreenLoadingA" @click="dialogConfirm('sendForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				fullscreenLoading: false,
				listLoading: false,
				page: 1,
				searchForm: {
					name: ''
				},//条件查询form
				total: 0,//分页总条数
				tableData: [],//表格
				checkArr: [
					{label: "头像", val: "headImg"},
					{label: "玩家名称", val: "niceName"},
					{label: "openId", val: "openId"},
					{label: "分数", val: "integral"},
					{label: "正确题数", val: "trueNum"},
					{label: "错误题数", val: "errorNum"}
				],
				dialogFormVisible: false,//修改、新增弹窗
				dialogSendVisibles: false,//发送消息弹窗
				sendForm: {
					title: '',
					address: '',
					startTime: '',
					tips: ''
				},
				sendRules: {
					title: [{ required: true, message: '请输入活动主题', trigger: 'blur' }],
					address: [{ required: true, message: '请输入赛事地点', trigger: 'blur' }],
					startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
					tips: [{ required: true, message: '请输入温馨提醒', trigger: 'blur' }]
				},
				fullscreenLoadingA: false,
			}
		},
		props: ['gameRefId', 'roomId', 'sort'],
		components: {
			
		},
		created(){
			this.getList();
		},
		methods:{
			getList() {
				this.listLoading = true;
				let data = {
					gameRefId: this.gameRefId,
					roomId: this.roomId,
					niceName: this.searchForm.name,
					pageIndex: this.page,
					pageSize: 10,
				}
				this.$comjs.ajax.getAjax('/fa/exam/game/getRoomUserList', data, this, res => {
					this.tableData = res.data.dataTable.dataList;
					this.total = res.data.dataTable.total;
					this.listLoading = false;
				});
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			// 查询
			searchTable() {
				this.page = 1;
				this.getList();
			},
			// 踢出
			outClick(obj) {
				this.fullscreenLoading = true;
				let data = {
					roomId: obj.roomId,
					wxUserId: obj.wxUserId
				}
				this.$comjs.ajax.postJsonAjax('/fa/exam/game/kick-out', data, this, res => {
					this.fullscreenLoading = false;
					this.$message({
						message: res.message,
						type: 'success'
					});
					setTimeout(() => {
						this.getList();
					}, 1500)
				}, res => {
					this.fullscreenLoading = false;
					this.$message({
						message: res.message,
						type: 'warning'
					});
				});
			},
			// 发送消息
			sendBtn() {
				this.dialogSendVisibles = true;
			},
			aaa() {
					let liArr = this.tableData;
					let str = `姓名,分数\n`;
			        // 增加\t为了不让表格显示科学计数法或者其他格式
			        for(let i = 0 ; i < liArr.length ; i++ ){
			//             for(const key in this.liArr[i]){
			                str+=`${liArr[i].niceName + '\t'},`;    
			                str+=`${liArr[i].integral + '\t'},`;     
			//             }
			            str+='\n';
			        }
			        // encodeURIComponent解决中文乱码
			        const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
			        // 通过创建a标签实现
			        const link = document.createElement("a");
			        link.href = uri;
			        // 对下载的文件命名
			        link.download =  "json数据表.csv";
			        link.click();
				},
			abc() {
				this.aaa();
			},
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							gameRefId: this.gameRefId,
							title: this.sendForm.title,
							address: this.sendForm.address,
							startTime: this.sendForm.startTime,
							tips: this.sendForm.tips
						}
						
						this.$confirm('是否发送消息', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.fullscreenLoadingA = true;
							this.$comjs.ajax.postAjax('/fa/user/send-template', data, this, res => {
								this.fullscreenLoadingA = false;
								this.dialogSendVisibles = false;
								this.$refs[formName].resetFields();
								// this.$message({
								// 	message: res.message,
								// 	type: 'success',
								// 	duration: 1000
								// });
								this.$alert(res.message, '提示', {
									customClass: 'alertPre',
									dangerouslyUseHTMLString: true
								});
							}, res => {
								this.fullscreenLoadingA = false;
								this.$message({
									message: res.message,
									type: 'warning',
									duration: 1000
								});
							});
						}).catch(() => {
							
						});
					}
				});
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	.elCard {
		margin-bottom: 20px;
	}
</style>


<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="赛事名称：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入赛事名称"></el-input>
					</el-form-item>
					<!-- <el-form-item label="区域：" prop="countyName">
						<el-select v-model="searchForm.countyName" clearable placeholder="请选择区域" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in areaArr" :key="item.id" :label="item.countyName" :value="item.countyName"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="省份/地区：" prop="provinceName">
					  <el-select v-model="searchForm.provinceName" value-key="id" placeholder="请选择省份/地区" @change="changeoptionsTop()">
						  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item"></el-option>
					  </el-select>
					</el-form-item>
					<el-form-item label="城市：" prop="cityName">
					  <el-select v-model="searchForm.cityName" value-key="id" placeholder="请选择城市" @change="changeoptions1Top()">
						  <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item"></el-option>
					  </el-select>
					</el-form-item>
					<el-form-item label="区/县：" prop="countyName">
					  <el-select v-model="searchForm.countyName" value-key="id" placeholder="请选择区/县">
						  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item"></el-option>
					  </el-select>
					</el-form-item>
					<el-form-item label="是否可用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="赛事时间：" prop="times">
						<el-date-picker
					      v-model="searchForm.times"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
						  value-format="yyyy-MM-dd HH:mm:ss"
						  :default-time="['00:00:00', '23:59:59']">
					    </el-date-picker>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-button type="warning" size="medium" icon="el-icon-time" @click="history()">历史赛事</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="nowStr = '';page = 1;getList()"></el-button>
		</el-row>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'overTime'">{{scope.row.overTime / 1000 / 60 + '分钟'}}</span>
						<span v-else-if="item.val == 'firstTime'">{{scope.row.firstTime / 1000 / 60 + '分钟'}}</span>
						<span v-else-if="item.val == 'answerTime'">{{scope.row.answerTime / 1000 + '秒'}}</span>
						<span v-else-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else-if="item.val == 'type'">{{scope.row.type == 1 ? '线上' : '线下'}}</span>
						<span v-else-if="item.val == 'activeStatus'">{{scope.row.activeStatus == 0 ? '未开始' : scope.row.activeStatus == 1 ? '进行中' : '已结束'}}</span>	 					
						<span v-else-if="item.val == 'advertLeft'"><img class="advImg" :src="scope.row.advertLeft" ></span>
						<span v-else-if="item.val == 'advertRight'"><img class="advImg" :src="scope.row.advertRight" ></span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="createdTxStamp" label="创建日期" min-width="150"></el-table-column> -->
				<el-table-column label="操作" min-width="215" fixed="right">
					<template slot-scope="scope">
						<el-button class="marginRight10" type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<el-button class="marginLeft0 marginRight10 marginTop5" type="success" size="mini" icon="el-icon-s-grid" @click="stageBtn(scope.$index, scope.row)">阶段管理</el-button>
						<!-- <el-button class="marginLeft0 marginTop5" type="success" size="mini" icon="el-icon-document-add" @click="qrcodeBtn(scope.$index, scope.row)">生成二维码</el-button> -->
						<el-button class="marginLeft0 marginTop5" type="success" size="mini" icon="el-icon-document-add" @click="machineBtn(scope.$index, scope.row)">设备访问链接</el-button>
						<el-switch v-model="scope.row.status" @change="switChange($event, scope.row)" active-color="#409eff" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog width="60%" :title="dialogTitle" :visible.sync="dialogFormVisible">
		  <el-form :inline="true" :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
		    <el-form-item label="赛事名称：" prop="name">
				<el-input v-model="form.name" placeholder="请输入赛事名称" autocomplete="off"></el-input>
		    </el-form-item>
			<el-form-item label="赛事类型：" prop="sType" :label-width="formLabelWidth">
			  <el-select v-model="form.sType" value-key="id" placeholder="请选择赛事类型">
				  <el-option v-for="item in sTypeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="省份/地区：" prop="region" :label-width="formLabelWidth">
			  <el-select v-model="form.region" value-key="id" placeholder="请选择省份/地区" @change="changeoptions()">
				  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item"></el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="城市：" prop="region1" :label-width="formLabelWidth">
			  <el-select v-model="form.region1" value-key="id" placeholder="请选择城市" @change="changeoptions1()">
				  <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item"></el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="区/县：" prop="region2" :label-width="formLabelWidth">
			  <el-select v-model="form.region2" value-key="id" placeholder="请选择区/县" @change="changeoptions2()">
				  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item"></el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="容纳人数：" prop="num">
				<el-input v-model="form.num" placeholder="请输入容纳人数" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.num = $event.target.value"></el-input>
			</el-form-item>
			<el-form-item label="总时长：" prop="overTime">
				<el-input v-model="form.overTime" placeholder="请输入总时长" style="max-width: 202px;" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.overTime = $event.target.value"><template slot="append">分</template></el-input>
			</el-form-item>
			<el-form-item label="第一阶段时长：" prop="firstTime">
				<el-input v-model="form.firstTime" placeholder="请输入总时长" style="max-width: 202px;" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.firstTime = $event.target.value"><template slot="append">分</template></el-input>
			</el-form-item>
			<el-form-item label="单题最大回答时间：" prop="answerTime">
				<el-input v-model="form.answerTime" placeholder="单题最大回答时间" style="max-width: 202px;" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.answerTime = $event.target.value"><template slot="append">秒</template></el-input>
			</el-form-item>
			<el-form-item label="单题积分：" prop="score" v-if="this.form.sType != 1">
				<el-input v-model="form.score" placeholder="请输入单题积分" style="max-width: 202px;" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.score = $event.target.value"></el-input>
			</el-form-item>
			<el-form-item label="每日答题次数：" prop="dayAnswerNum" v-if="this.form.sType != 1">
				<el-input v-model="form.dayAnswerNum" placeholder="请输入每日答题次数" style="max-width: 202px;" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.dayAnswerNum = $event.target.value"></el-input>
			</el-form-item>
			<el-form-item label="答题总次数：" prop="answerNum" v-if="this.form.sType != 1">
				<el-input v-model="form.answerNum" placeholder="请输入答题总次数" style="max-width: 202px;" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.answerNum = $event.target.value"></el-input>
			</el-form-item>
			<el-form-item label="线上线下：" prop="type">
				<el-select v-model="form.type" clearable placeholder="请选择区域" autocomplete="off" @change="typeChange" style="max-width: 202px;">
					<el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="邀请码：" prop="code">
				<el-input v-model="form.code" placeholder="请输入邀请码" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.code = $event.target.value"></el-input>
			</el-form-item>
			<el-form-item label="主办方：" prop="organizers">
				<el-input v-model="form.organizers" placeholder="请输入主办方" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="承办方：" prop="undertake">
				<el-input v-model="form.undertake" placeholder="请输入承办方" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="开始时间：" prop="startTime">
				<el-date-picker v-model="form.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" style="max-width: 202px; width: auto;" placeholder="请选择开始时间"></el-date-picker>
			</el-form-item>
			<el-form-item label="流程模板：" prop="templateId">
				<el-select v-model="form.templateId" filterable clearable placeholder="请选择流程模板" autocomplete="off" style="max-width: 202px;" :loading="templateLoading">
					<el-option v-for="item in templateArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="左侧广告：" prop="advertLeft">
				<el-select v-model="form.advertLeft" filterable clearable placeholder="请选择左侧广告" autocomplete="off" style="max-width: 202px;" :loading="advLoading">
					<el-option v-for="item in advArr" :key="item.id" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="右侧广告：" prop="advertRight">
				<el-select v-model="form.advertRight" filterable clearable placeholder="请选择右侧广告" autocomplete="off" style="max-width: 202px;" :loading="advLoading">
					<el-option v-for="item in advArr" :key="item.id" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="下一环节：" prop="nextGameId">
				<el-select v-model="form.nextGameId" filterable clearable placeholder="请选择下一环节" autocomplete="off" style="max-width: 202px;" :loading="nextLoading">
					<el-option v-for="item in nextArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
		    <el-form-item label="当前虚拟人数：">
				<el-input v-model="form.fictitiousNum" placeholder="请输入当前虚拟人数" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.fictitiousNum = $event.target.value"></el-input>
		    </el-form-item>
		    <el-form-item label="每日新增虚拟人数：">
				<el-input v-model="form.addFictitiousNum" placeholder="请输入每日新增虚拟人数" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.addFictitiousNum = $event.target.value"></el-input>
		    </el-form-item>
		    <el-form-item label="备注：">
				<el-input type="textarea" v-model="form.remark" placeholder="请输入备注" autocomplete="off"></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoadingA" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
		<el-dialog title="阶段管理" :fullscreen="true" :visible.sync="quesVisible" @close="page = 1;getList()">
<!--			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" v-loading.fullscreen.lock="fullscreenLoading" @click="createRoom" style="margin-bottom: 15px;">创建房间</el-button>-->
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="switchClick" style="margin-bottom: 15px;">切换</el-button>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="resetClick" style="margin-bottom: 15px;">重置</el-button>
			<el-button type="danger" size="medium" icon="el-icon-warning" @click="endGame" style="margin-bottom: 15px;">赛事结束</el-button>
			<gameStage :key="timeKey" :gameId="gameId" ref="gameStage"></gameStage>
		</el-dialog>
		<el-dialog width="300px" title="二维码" :visible.sync="qrcodeVisible" center>
			<vue-qr :logoSrc="qrLogoUrl" :text="qrCordUrl" :size="200" style="display: block; margin: 0 auto; box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);"></vue-qr>
			<div slot="footer" class="dialog-footer">
				<el-button @click="qrcodeVisible = false">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog  width="60%" title="设备访问链接" :visible.sync="machineVisible" center>
			<el-form :inline="true" :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
			  <el-form-item label="pc端链接：" v-if="machineUrlMap.pc">
				<el-input type="textarea"  v-model="machineUrlMap.pc" :autosize="{ minRows: 3, maxRows: 6}"></el-input>
			  </el-form-item>
			  <el-form-item label="准确率链接：" v-if="machineUrlMap.line">
			  	<el-input type="textarea"  v-model="machineUrlMap.line" :autosize="{ minRows: 3, maxRows: 6}"></el-input>
			  </el-form-item>
			  <el-form-item label="ipad1链接：" v-if="machineUrlMap.ipad1">
			  	<el-input type="textarea"  v-model="machineUrlMap.ipad1" :autosize="{ minRows: 3, maxRows: 6}"></el-input>
			  </el-form-item>
			  <el-form-item label="ipad2链接：" v-if="machineUrlMap.ipad2">
			  	<el-input type="textarea"  v-model="machineUrlMap.ipad2"  :autosize="{ minRows: 3, maxRows: 6}"></el-input>
			    </el-form-item>
			</el-form>	
			
			<div slot="footer" class="dialog-footer">
				<el-button @click="machineVisible = false">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import gameStage from "../../../components/gameStage"
	import vueQr from 'vue-qr'
	export default {
		data() {
			return {
				listLoading: false,
				qrcodeVisible: false,//二维码弹窗
				machineVisible: false,//设备弹窗
				qrLogoUrl: 'http://localhost:8080/img/logo.6390d52e.png',//二维码logo
				qrCordUrl: '123456',//二维码内容
				fullscreenLoading: false,//创建房间loading
				fullscreenLoadingA: false,//保存loading
				dialogTitle: '修改数据',
				page: 1,
				machineUrlMap: {
					pc: "",
					line: "",
					ipad1: "",
					ipad2: ""
				},
				searchForm: {
					name: '',
					status: 1,
					countyName: '',
					times: ''
				},//条件查询form
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否可用
				templateArr: [],//流程模板
				advArr: [],//广告模板
				nextArr: [],//下一环节
				templateLoading: false,
				advLoading: false,
				nextLoading: false,
				areaArr: [], //搜索-区域数据
				options: [],
				options1: [],
				options2: [],//下拉框数据
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				total: 0,//分页总条数
				dialogFormVisible: false,//修改、新增弹窗
				formLabelWidth: '150px',//修改、新增弹窗-label宽度
				typeArr: [{
					value: 1,
					label: "线上"
				}, {
					value: 0,
					label: "线下"
				}],//类型选择数组
				sTypeArr: [{
					value: "1",
					label: "普通赛"
				}, {
					value: "2",
					label: "大众赛"
				}, {
					value: "3",
					label: "积分赛"
				}],//赛事类型数组
				form: {
					name: '',
					sType: '',
					score: '',
					dayAnswerNum: '',
					answerNum: '',
					provinceId: '',
					region: '',
					cityId: '',
					region1: '',
					countyId: '',
					region2: '',
					num: '',
					overTime: '',
					firstTime: '',
					answerTime: '',
					type: '',
					code: '',
					organizers: '',
					undertake: '',
					remark: '',
					startTime: '',
					templateId: '',
					advertLeft: '',
					advertRight: '',
					nextGameId: '',
					fictitiousNum: '',
					addFictitiousNum: ''
				},//修改、新增弹窗-表单内容
				rules: {
					name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
					sType: [{ required: true, message: '请选择赛事类型', trigger: 'change' }],
					// score: [{ required: true, message: '请输入单题积分', trigger: 'blur' }],
					dayAnswerNum: [{ required: true, message: '请输入每日答题次数', trigger: 'blur' }],
					answerNum: [{ required: true, message: '请输入答题总次数', trigger: 'blur' }],
					region: [{ required: true, message: '请选择省份/地区', trigger: 'change' }],
					region1: [{ required: true, message: '请选择城市', trigger: 'change' }],
					region2: [{ required: true, message: '请选择区/县', trigger: 'change' }],
					num: [{ required: true, message: '请输入容纳人数', trigger: 'blur' }],
					overTime: [{ required: true, message: '请输入总时长', trigger: 'blur' }],
					firstTime: [{ required: true, message: '请输入第一阶段时长', trigger: 'blur' }],
					answerTime: [{ required: true, message: '请输入单题最大回答时间', trigger: 'blur' }],
					type: [{ required: true, message: '请选择线上线下', trigger: 'change' }],
					// code: [{ required: true, message: '请选择邀请码', trigger: 'blur' }],
					organizers: [{ required: true, message: '请输入主办方', trigger: 'blur' }],
					undertake: [{ required: true, message: '请输入承办方', trigger: 'blur' }],
					// remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
					startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
					templateId: [{ required: true, message: '请选择流程模板', trigger: 'change' }]
				},
				visible: false,//表格右上角多选框
				checkArr: [
					{label: "赛事名称", val: "name", dis: true}, 
					{label: "赛事类型", val: "sType", dis: true}, 
					{label: "单题积分", val: "score", dis: true}, 
					{label: "每日答题次数", val: "dayAnswerNum", dis: true}, 
					{label: "答题总次数", val: "answerNum", dis: true}, 
					{label: "区域", val: "countyName", dis: true}, 
					{label: "容纳人数", val: "num", dis: true}, 
					{label: "总时长", val: "overTime", dis: true}, 
					{label: "结束时间", val: "endTime", dis: true},
					{label: "第一阶段时长", val: "firstTime", dis: true}, 
					{label: "单题最大回答时间", val: "answerTime", dis: true}, 
					{label: "运行状态", val: "activeStatus", dis: true}, 
					{label: "是否可用", val: "status", dis: true}, 
					{label: "线上线下", val: "type", dis: true}, 
					{label: "邀请码", val: "code", dis: true}, 
					{label: "主办方", val: "organizers", dis: true}, 
					{label: "承办方", val: "undertake", dis: true}, 
					{label: "左侧广告", val: "advertLeft", dis: true}, 
					{label: "右侧广告", val: "advertRight", dis: true}, 
					{label: "下一环节", val: "nextGameId", dis: true}, 
					{label: "当前虚拟人数", val: "fictitiousNum", dis: true}, 
					{label: "每日新增虚拟人数", val: "addFictitiousNum", dis: true}, 
					{label: "备注", val: "remark", dis: true}, 
					{label: "开始时间", val: "startTime", dis: true},
					{label: "创建日期", val: "createdTxStamp", dis: true}],
				checkList: ["name", "sType", "countyName", "num", "overTime", "endTime", "firstTime", "answerTime", "score", "dayAnswerNum", "answerNum", "activeStatus", "status", "type", "code", "organizers", "undertake", "remark", "startTime", "createdTxStamp", "advertLeft", "advertRight", "nextGameId", "fictitiousNum", "addFictitiousNum"],
				quesVisible: false,//阶段管理弹窗
				gameId: '',
				timeKey: '',
				nowStr: ''
			}
		},
		components: {
			gameStage, vueQr
		},
		created(){
			this.getList();
			// this.getBtn()
			this.getOptions(-1, 'options');
		},
		methods:{
			getList() {
				this.listLoading = true;
				if(!this.searchForm.times) {
					this.searchForm.times = [];
				}
				// 查询内容转数组
				// let obj = {
				// 	name: this.searchForm.name,
				// 	status: this.searchForm.status,
				// 	countyName: this.searchForm.countyName,
				// 	startTime: this.searchForm.times[0],
				// 	endTime: this.searchForm.times[1]
				// };
				// let arr = Object.keys(obj);
				// let arrs = [];
				// arr.forEach(item => {
				// 	let o = {};
				// 	o.col = item;
				// 	o.val = obj[item];
				// 	if(item == "status") {
				// 		o.type = "=";
				// 	} else if(item == "startTime") {
				// 		o.type = ">=";
				// 	} else if(item == "endTime") {
				// 		o.type = "<=";
				// 	} else {
				// 		o.type = "like";
				// 	}
				// 	arrs.push(o);
				// })
				let arrs = this.nowStr ? [{"col":"endTime","type":"<","val": this.nowStr }] : [
					{"col":"name","val": this.searchForm.name,"type":"like"},
					{"col":"status","val": this.searchForm.status,"type":"="},
					{"col":"provinceName","val": this.searchForm.provinceName ? this.searchForm.provinceName.name : '',"type":"like"},
					{"col":"cityName","val": this.searchForm.cityName ? this.searchForm.cityName.name : '',"type":"like"},
					{"col":"countyName","val": this.searchForm.countyName ? this.searchForm.countyName.name : '',"type":"like"},
					{"col":"startTime","val": this.searchForm.times[0],"type":">="},
					{"col":"startTime","val": this.searchForm.times[1],"type":"<="},
				]
				let data = {
					entity: 'FaExamGame',
					page: this.page,
					pageSize: 10,
					filter: JSON.stringify(arrs),
					// sidx: 'sort',
					// sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			//获取下拉框数据
			getOptions(code,options) {
				let data = {
					code: code
				}
				this.$comjs.ajax.getAjax('/address/info/getAddress', data, this, res => {
					console.log(res)
					this[options] = res.list
				})
			},
			//搜索-获取区域
			getBtn() {
				let datas = {
					entity: 'FaExamArea',
					filter: '[{"col":"status","type":"=","val":"1"}]',
					sidx: 'sort'
				}
				this.$comjs.ajax.getAjax('/jqGrid/findFaList', datas, this, res => {
					console.log(res)
					this.areaArr = res.list;
				})
			},
			// 获取流程模板-广告模板-下一环节
			getTemplate(id) {
				this.templateLoading = true;
				this.advLoading = true;
				this.nextLoading = true;
				let data = {
					entity: 'FaExamGameTemplate',
					filter: '[{"col":"status","type":"=","val":"1"}]',
					sidx: 'sort'
				}
				this.$comjs.ajax.getAjax('/jqGrid/findFaList', data, this, res => {
					this.templateArr = res.list;
					this.templateLoading = false;
				});
				this.$comjs.ajax.getAjax('/fa/exam/advert/query', {type: 2}, this, res => {
					this.advArr = res.list;
					this.advLoading = false;
				});
				this.$comjs.ajax.getAjax('/fa/exam/game/online-game', {gameId: id ? id : ''}, this, res => {
					this.nextArr = res.list;
					this.nextLoading = false;
				});
			},
			// 搜索
			searchTable() {
				this.nowStr = '';
				this.page = 1;
				this.getList();
			},
			// 重置
			resetForm(formName) {
				this.nowStr = '';
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			// 新增
			append() {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.options1 = []
				this.options2 = []
				this.dialogTitle = "新增数据";
				this.dialogFormVisible = true;
				this.getTemplate();
				this.form = {
					id: '',
					name: '',
					sType: '',
					score: '',
					dayAnswerNum: '',
					answerNum: '',
					provinceId: '',
					region: '',
					cityId: '',
					region1: '',
					countyId: '',
					region2: '',
					num: '',
					overTime: '',
					firstTime: '',
					answerTime: '',
					type: '',
					code: '',
					organizers: '',
					undertake: '',
					remark: '',
					startTime: '',
					templateId: '',
					advertLeft: '',
					advertRight: '',
					nextGameId: '',
					fictitiousNum: '',
					addFictitiousNum: ''
				}
			},
			// 历史赛事
			history() {
				let now = new Date();
				this.page = 1;
				this.nowStr = now.format("yyyy-MM-dd hh:mm:ss");
				this.getList();
				// this.listLoading = true;
				// let data = {
				// 	entity: 'FaExamGame',
				// 	page: this.page,
				// 	pageSize: 10,
				// 	filter: '[{"col":"endTime","type":"<","val":"' + nowStr + '"}]',
				// 	// sidx: 'sort',
				// 	// sord: 'asc'
				// }
				// this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
				// 	this.tableData = res.rows;
				// 	this.total = res.total;
				// 	this.listLoading = false;
				// });
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			// 修改
			editTable(index, row) {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.dialogFormVisible = true;
				this.dialogTitle = "修改数据";
				let data = {
					id: row.id
				}
				this.$comjs.ajax.getAjax('/fa/exam/game/gameDetail', data, this, res => {
					this.form = {
						id: res.data.id,
						name: res.data.name,
						sType: res.data.gameType,
						score: res.data.score,
						dayAnswerNum: res.data.dayAnswerNum,
						answerNum: res.data.answerNum,
						provinceId: res.data.provinceId,
						region: res.data.provinceName,
						cityId: res.data.cityId,
						region1: res.data.cityName,
						countyId: res.data.countyId,
						region2: res.data.countyName,
						num: res.data.num,
						overTime: res.data.overTime / 1000 / 60,
						firstTime: res.data.firstTime / 1000 / 60,
						answerTime: res.data.answerTime / 1000,
						type: res.data.type,
						code: res.data.code,
						organizers: res.data.organizers,
						undertake: res.data.undertake,
						remark: res.data.remark,
						startTime: res.data.startTime,
						templateId: res.data.templateId,
						advertLeft: res.data.advertLeft,
						advertRight: res.data.advertRight,
						nextGameId: res.data.nextGameId,
						fictitiousNum: res.data.fictitiousNum,
						addFictitiousNum: res.data.addFictitiousNum
					}
					console.log(res.data.provinceId)
					console.log(res.data.cityId)
					this.getOptions(res.data.provinceId, 'options1')
					this.getOptions(res.data.cityId, 'options2')
				});
				this.getTemplate(row.id);
			},
			// 阶段管理
			stageBtn(index, row) {
				this.timeKey = new Date().getTime();
				this.gameId = row.id;
				this.quesVisible = true;
			},
			// 生成二维码
			qrcodeBtn(index, row) {
				this.qrcodeVisible = true;
			},
			machineBtn(index, row) {
				this.machineVisible = true;
				let publics = process.env.NODE_ENV == 'production' ? '/game-pc' : '/';
				// if(row.type == 1) {//线上赛
				// 	this.machineUrlMap.pc = '';
				// 	this.machineUrlMap.line = document.location.protocol + "//" + document.location.host + publics + "#/online?id="+row.id;
				// 	this.machineUrlMap.ipad1 = '';
				// 	this.machineUrlMap.ipad2 = '';
				// } else {//线下赛
					this.machineUrlMap.pc = document.location.protocol + "//" + document.location.host + publics + "#/ranking?id="+row.id;
					this.machineUrlMap.line = document.location.protocol + "//" + document.location.host + publics + "#/online?id="+row.id;
					this.machineUrlMap.ipad1 = document.location.protocol + "//" + document.location.host + publics + "#/choose?id="+row.id;
					this.machineUrlMap.ipad2 = document.location.protocol + "//" + document.location.host + publics + "#/champion?id="+row.id;
				// }
				
				
			},
			// 表格-是否启用
			switChange(val, row) {
				let data = {
					id: row.id,
					status: val,
					entity: 'FaExamGame'
				}
				this.$comjs.ajax.postAjax('/jqGrid/changeStatus', data, this, res => {
					this.$message({
						message: res.message,
						type: 'success'
					});
					this.getList();
				});
			},
			//下拉框值改变
			changeoptions() {
				this.getOptions(this.form.region.code,'options1')
				this.form.provinceId = this.form.region.code
				if(this.dialogTitle === '新增数据') {
				} else {
					this.form.region = this.form.region.name
				}
				this.form.region1 = ''
				this.form.region2 = ''
			},
			// 选择线上线下
			typeChange() {
				// if(!this.form.type) {//线下-清空邀请码
				// 	this.form.code = '';
				// }
			},
			changeoptionsTop() {
				this.getOptions(this.searchForm.provinceName.code,'options1')
				this.searchForm.cityName = ''
				this.searchForm.countyName = ''
			},
			changeoptions1Top() {
				this.getOptions(this.searchForm.cityName.code,'options2')
				this.searchForm.countyName = ''
			},
			changeoptions1() {
				this.getOptions(this.form.region1.code,'options2')
				this.form.cityId = this.form.region1.code
				if(this.dialogTitle === '新增数据') {
				} else {
					this.form.region1 = this.form.region1.name
				}
				this.form.region2 = ''
			},
			changeoptions2() {
				this.form.countyId = this.form.region2.code
				if(this.dialogTitle === '新增数据') {
				} else {
					this.form.region2 = this.form.region2.name
				}
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.form)
						if (this.dialogTitle === '新增数据') {
							var data = {
								name: this.form.name,
								gameType: this.form.sType,
								score: this.form.sType == 1 ? '' : this.form.score == 0 ? '' : this.form.score,
								dayAnswerNum: this.form.sType == 1 ? '' : this.form.dayAnswerNum,
								answerNum: this.form.sType == 1 ? '' : this.form.answerNum,
								provinceId: this.form.region.code,
								provinceName: this.form.region.name,
								cityId: this.form.region1.code,
								cityName: this.form.region1.name,
								countyId: this.form.region2.code,
								countyName: this.form.region2.name,
								num: this.form.num,
								overTime: this.form.overTime * 60 * 1000,
								firstTime: this.form.firstTime * 60 * 1000,
								answerTime: this.form.answerTime * 1000,
								type: this.form.type,
								code: this.form.code,
								organizers: this.form.organizers,
								undertake: this.form.undertake,
								remark: this.form.remark,
								startTime: this.form.startTime,
								templateId: this.form.templateId,
								advertLeft: this.form.advertLeft,
								advertRight: this.form.advertRight,
								nextGameId: this.form.nextGameId,
								fictitiousNum: this.form.fictitiousNum,
								addFictitiousNum: this.form.addFictitiousNum
							}
						}
						else {
							var data = {
								id: this.form.id,
								name: this.form.name,
								gameType: this.form.sType,
								score: this.form.sType == 1 ? '' : this.form.score == 0 ? '' : this.form.score,
								dayAnswerNum: this.form.sType == 1 ? '' : this.form.dayAnswerNum,
								answerNum: this.form.sType == 1 ? '' : this.form.answerNum,
								provinceId: this.form.provinceId,
								provinceName: this.form.region,
								cityId: this.form.cityId,
								cityName: this.form.region1,
								countyId: this.form.countyId,
								countyName: this.form.region2,
								num: this.form.num,
								overTime: this.form.overTime * 60 * 1000,
								firstTime: this.form.firstTime * 60 * 1000,
								answerTime: this.form.answerTime * 1000,
								type: this.form.type,
								code: this.form.code,
								organizers: this.form.organizers,
								undertake: this.form.undertake,
								remark: this.form.remark,
								startTime: this.form.startTime,
								templateId: this.form.templateId,
								advertLeft: this.form.advertLeft,
								advertRight: this.form.advertRight,
								nextGameId: this.form.nextGameId,
								fictitiousNum: this.form.fictitiousNum,
								addFictitiousNum: this.form.addFictitiousNum
							}
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.fullscreenLoadingA = true;
							this.$comjs.ajax.postAjax('/fa/exam/game/editGame', data, this, res => {
								this.fullscreenLoadingA = false;
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '保存成功！',
									type: 'success',
									duration: 1000
								});
								this.nowStr = '';
								this.getList();
							});
						}).catch(() => {
							
						});
					}
				});
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},
			// 创建房间
			createRoom() {
				this.fullscreenLoading = true;
				this.$comjs.ajax.postAjax('/fa/exam/game/createRoomByGame', {gameId: this.gameId}, this, res => {
					this.fullscreenLoading = false;
					this.$message({
						message: '创建成功！',
						type: 'success',
						duration: 1000
					});
				});
			},
			// 切换
			switchClick() {
				this.fullscreenLoading = true;
				this.$comjs.ajax.getAjax('/fa/exam/game/startNextStage', {gameId: this.gameId}, this, res => {
					this.fullscreenLoading = false;
					this.timeKey = new Date().getTime();
					this.$message({
						message: '切换成功！',
						type: 'success',
						duration: 1000
					});
				}, res => {
					this.fullscreenLoading = false;
				});
			},
			// 重置
			resetClick() {
				// console.log(this.$refs.gameStage.stageId)
				this.$confirm('请选择重置阶段', '提示', {
				  confirmButtonText: '全部重置',
				  cancelButtonText: '重置当前阶段',
				  distinguishCancelAndClose: true,
				  type: 'warning'
				}).then(() => {//全部重置
					this.fullscreenLoading = true;
					this.$comjs.ajax.getAjax('/fa/exam/game/resetAllStages', {gameId: this.gameId, resetAll: true}, this, res => {
						this.fullscreenLoading = false;
						this.timeKey = new Date().getTime();
						this.$message({
							message: '重置成功！',
							type: 'success',
							duration: 1000
						});
					});
				}).catch((action) => {//重置当前阶段
					if(action == 'cancel') {
						this.fullscreenLoading = true;
						this.$comjs.ajax.getAjax('/fa/exam/game/resetAllStages', {gameId: this.gameId, resetAll: false}, this, res => {
							this.fullscreenLoading = false;
							this.timeKey = new Date().getTime();
							this.$message({
								message: '重置成功！',
								type: 'success',
								duration: 1000
							});
						});
					}
				});
			},
			//赛事结束
			endGame() {
				this.fullscreenLoading = true;
				this.$comjs.ajax.getAjax('/fa/exam/game/endGame', {gameId: this.gameId}, this, res => {
					this.fullscreenLoading = false;
					this.timeKey = new Date().getTime();
					this.$message({
						message: res.message,
						type: 'success',
						duration: 1000
					});
				});
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	.advImg {
		width: 80px;
		// height: 80px;
	}
</style>

